import {imageFile} from "../services/file";

export const loadImage = (imageUrl, cb) => {
  if (!imageUrl) {
    return cb && cb('skipped');
  }

  const load = async () => {
    const image = await imageFile(imageUrl);
    const file = new File([new Blob([image.data])], "name");

    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setTimeout(() => {
        cb && cb(null, reader.result);
      }, 200);
    }
  };

  load();
};

import { COMPANYPROFILE } from "../actions/CompanyProfile";

export const initialState = {
  list: {}
}


const company = (state: any = initialState, action: any) => {
  switch (action.type) {
    case COMPANYPROFILE:
      return {
        list: action.payload
      };
    default:
      break;
  }
  return state;
}

export default company;
import { HISTORY } from "../actions/History";

export const initialState = {
  list: []
}


const historyReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case HISTORY:
      return {
        ...state,
        list: action.payload
      };
    default:
      break;
  }
  return state;
}

export default historyReducer;
import axios from "./axios";

export const getAllPrescreening = (payload) => {
  return axios.post(`/pre_screening/get`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getPrescreeningByPrescreeningId = (payload, prescreeningId) => {
  return axios.get(`/pre_screening/${prescreeningId}`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const createPreScreening = (payload) => {
  return axios.post(`/pre_screening/create`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const editPreScreening = (payload) => {
  return axios.post(`/pre_screening/edit`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const sendPreScreeningReminder = (payload) => {
  return axios.post(`candidate_pre_screening_round/re-send-mail`,payload)
    .then((response) => response)
    .catch((err) => {
       throw err?.response;
    });
}

export const get_prescreen_rightDrawer = (payload={}) => {
  return axios.post(`candidate_pre_screening_round/candidate`,payload)
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 


export const fetchPrescreening = (payload) => {
  return axios.post(`/candidate_pre_screening_round/all`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const getPrescreeningStats = (payload) => {
  return axios.post(`/candidate_pre_screening_round/stats`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}


export const getPreScreening = (position_id,payload={}) => {
  return axios.post(`/position_pre_screening/${position_id}`,payload)
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 


export const getPreScreeningDetails = (prescreenId) => {
  return axios.get(`/pre_screening/${prescreenId}`)
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 

export const prescreeningTest_login = (jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.get("/candidate_pre_screening_round/info", {
    headers: headers
  })
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 


export const getPrescreenReportQuestions= (payload, candidate_pre_screening_round_id) => {
  return axios.post(`/candidate_pre_screening_round/get_asked_questions/${candidate_pre_screening_round_id}`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const prescreeningTest_submit = (payload, jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.post("/candidate_pre_screening_round/submit_candidate_response", payload, {
    headers: headers
  })
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 



export const send_otp = (jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.get("/candidate_pre_screening_round/send-otp", {
    headers: headers
  })
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err.response;
    })
} 


export const validate_otp = (payload, jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.post(`/candidate_pre_screening_round/verify-otp`,payload, {
    headers: headers
  })
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const prescreenTestEdit = (candidate_pre_screening_round_id, payload) => {
  return axios.post(`/candidate_pre_screening_round/edit/${candidate_pre_screening_round_id}`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
}

export const prescreeningStartTest = (jwtToken) => {
  const headers = {
    'X-Candidate-Request-Access-Token': jwtToken,
  }
  return axios.get("candidate_pre_screening_round/can_start_test", {
    headers: headers
  })
    .then((response)=>{
      return response; 
    })
    .catch((err)=>{
      return err?.response;
    })
} 
export const getFilterOptionsAPI = () => {
  return axios.get(`/pre_screening_question_bank/filters`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
};

export const getQuestionBankListAPI = (payload = {}) => {
  return axios.post(`/pre_screening_question_bank/get_pre_screening_questions`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error?.response;
    });
};

export const getShareableLinkAPI = (id)=>{
  return axios.get(`/guides/${id}/share`).then((res)=>{
      return res;
    }).catch((error)=>{
    return error?.response;
  })
}

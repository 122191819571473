export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const forgotSuccess = (user: any) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: user,
});

export const forgotFailed = (user: any) => ({
  type: FORGOT_PASSWORD_FAILED,
});

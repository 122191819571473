import { INTERVIEW_FETCH_SUCCESS } from "../actions/Interview";

export const initialState = {
  list: []
};


const interviewReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case INTERVIEW_FETCH_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    default:
      break;
  }
  return state;
};

export default interviewReducer;

import {UPDATE_CLIENT_LIST} from "../actions/Clients";

const initialState = [];

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_LIST:
      return action.payload.clients;

    default:
      return state;
  }
};

export default clientsReducer;

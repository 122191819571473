import { EDIT_INTERVIEW } from "../actions/EditInterview";

export const initialState = {
  list: {}
}


const edit_interview = (state: any = initialState, action: any) => {
  switch (action.type) {
    case EDIT_INTERVIEW:
      return {
        list: action.payload
      };
    default:
      break;
  }
  return state;
}

export default edit_interview;
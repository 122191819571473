import axios from "./axios";

const getTemporaryFeedbackAccessToken = () => {
  const accessToken = sessionStorage.getItem('temporary_access_token');
  if (accessToken) {
    return {
      'X-Client-Request-Access-Token': accessToken,
      'Authorization':null,
    };
  }

  return {};
};

export const getFeedback = async (interview_id) => {
    try{
        const resp = await axios.get(`/interview/${interview_id}/feedback`,  {
          headers: { ...getTemporaryFeedbackAccessToken() }
        });
        return resp?.data;
    } catch(err){
        throw(err)
    }
};


export const getShareableFeedbackLink = async (interview_id) => {
  try{
    const resp = await axios.get(`/interview/${interview_id}/feedback/share_link`,  {
      headers: { ...getTemporaryFeedbackAccessToken() }
    });
    return resp?.data;
  } catch(err){
    throw(err)
  }
};


export const verifyFeedbackAccess = async (token) => {
  try {
    const result = await axios.get('/interview/feedback/verify_client_access', {
      headers: {
        'X-Client-Request-Access-Token': token
      }
    });

    return result.data;
  } catch (err){
    throw err;
  }
};
import axios from 'axios';
import env from '../config/env';

export default axios.create({
    baseURL: env.api.baseUrl,
    headers: {
        'X-Client-Id': 'iv-client-web-app'
    }
});

console.warn("process.env.NODE_ENV" ,process.env.NODE_ENV);

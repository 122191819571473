export const SET_AUTH_USER = 'SET_AUTH_USER';
export const TIME_ZONE_ADD = 'TIME_ZONE_ADD';
export const USER_NAME_ADD = 'USER_NAME_ADD';

export const setAuthUser = (user = {}) => ({
  type: SET_AUTH_USER,
  payload: user
});

export const clearAuthUser = () => ({
  type: SET_AUTH_USER,
  payload: {}
});

export const updateTimeZone = (timeZone) => {
  return {
    type: TIME_ZONE_ADD,
    payload:timeZone
  }
};

export const updateUserName = (userName) => {
  return {
    type: USER_NAME_ADD,
    payload:userName
  }
};

import {
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILED,
  } from "../actions/ForgotPassword";

  export const initialState = {};

  const forgotPasswordReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
      case FORGOT_PASSWORD_SUCCESS:
        return action.payload;
      case FORGOT_PASSWORD_FAILED:
        return initialState;
      default:
        return state;
    }
  };

  export default forgotPasswordReducer;

import {UPDATE_CLIENT_IMAGE} from "../actions/ClientImages";

const initialState = {};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_IMAGE:
      return { ...state, [action.payload.clientId]: action.payload.image };

    default:
      return state;
  }
};

export default clientsReducer;

import React from 'react'
import { withRouter } from 'react-router-dom';

const RouteChangeTracker = ({ history }) => {

  history.listen((location) => {
    if (!window.gtag) {
      return;
    }

    window.gtag('set', 'page_path', location.pathname);
    window.gtag('event', 'page_view');
  });

  return <></>;
};

export default withRouter(RouteChangeTracker);

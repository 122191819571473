import axios from "./axios";

interface loginProps {
    email: string,
    password: string,
}

export const loginUser = async (user: loginProps) => {
    const resp = await axios.post('/login', user);
    return resp.data;
};

export const refreshAuth = (refresh_token: string) => {
    return axios.post('/auth/refresh', {}, {
        headers: {
            'Authorization': `Bearer ${refresh_token}`
        }
    })
        .then(response => {
            return response.data;
        })
};

export const logoutUser = async () => {
    const resp = await axios.get('/logout');
    return resp.data;
};

export const requestAccess = (authToken: string) => {
    return axios.get('/admin/request_access', {
        headers: {
            'X-Admin-Request-Access-Token': authToken
        }
    })
        .then(({ data }) => data);
};
import React from "react";
// import "./NotFound.scss";
// import "../NotFound/NotFound.scss"
import "../../atoms/NotFound/NotFound.scss"
// @material Components
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const TokenError = ({ error_msg="" }) => {
  const ErrorMsg ={
    fontWeight:"bold",
    marginBottom:"0px",
    marginTop:"20px",
    width:"380px",
    textAlign:"right"
  }

  const handleReload = ()=>{
    window.location.reload();
  }

  return (
    <div>
      <div className="not-found">
          <img className="not-found-img" src="/images/error.svg" alt="error svg" style={{marginTop:"100px"}} />
          <Box display="flex" flexDirection="column" style={{justifyContent:"space-evenly", marginTop:"100px"}}>
            <div>
              <Typography className="went-wrong">Something</Typography>
              <Typography className="went-wrong">went wrong!</Typography>
              {error_msg?.length >0 ?
                <p style={ErrorMsg}> Error: {error_msg}</p>:''
              }
              </div>
            <div className="sorry-text-container">
              <Typography className="sorry-txt">
                We are sorry for the inconvinience occured.
              </Typography>
              <Typography className="sorry-txt">
                There must be some problem loading.
              </Typography>
              <Typography className="sorry-txt">
                Please try re-loading the page.
              </Typography>
            </div>

            <Box
              className="reload-btn"
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                className="reload-page"
                onClick={handleReload}
              >
                Reload page
              </Button>
            </Box>
          </Box>
      </div>
    </div>
  );
};

export default TokenError;

import React from "react";
import { Redirect, Route } from "react-router-dom";

const ClientChooserRoute = ({ component: Component, isAuthenticated, isClientSelected, ...children }) => {
  if(!isAuthenticated) {
    return <Redirect to="/login" />
  }

  if(isClientSelected) {
    return <Redirect to="/dashboard" />
  }

  return <Route {...children} render={props => <Component {...props} {...children} />} />
};

export default ClientChooserRoute;

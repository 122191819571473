import { RESCHEDULE} from "../actions/Reschedule";

export const initialState = {
  list: {}
}


const reschedule = (state: any = initialState, action: any) => {
  switch (action.type) {
    case RESCHEDULE:
      return {
        list: action.payload
      };
    default:
      break;
  }
  return state;
}

export default reschedule;
import UserRoles from "../constants/UserRoles";

const handleClient = (roles) => {
  let flag = false;
  Object.keys(roles).map((e) => {
    if (e === "CLIENT_USER") flag = true;
  });
  return flag;
};

export default handleClient;

export const handleClientAdmin = (value) => {
  return value === UserRoles?.ADMIN;
};

import { DASHBOARDSTATS } from "../actions/DashboardStat";

export const initialState = {
  list: []
}


const interviewReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case DASHBOARDSTATS:
      return {
        list: action.payload
      };
    default:
      break;
  }
  return state;
}

export default interviewReducer;
import axios from "./axios";
import UserRoles from "../constants/UserRoles";

export const getClientListForUserRole = (role) => {
  const userType = Object.keys(role).map((e) => {return e });
  if (userType.includes(UserRoles.CLIENT_USER)) {
    return getClient().then((client) => [client]);
  } else if (userType.includes(UserRoles.HEADHUNTER)) {
    return getHeadhunterClients();
  } else {
    return console.error("user does not have correct role");
  }
};

export const getClient = () => {
  return axios.get('/client_user/client')
    .then((response) => response.data);
};

export const getHeadhunterClients = () => {
  return axios.get(`/headhunter_user/clients`)
    .then((response) => response.data);
};

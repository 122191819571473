import {
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
  } from "../actions/resetPassword";

  export const initialState = {};

  const resetPasswordReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
      case RESET_PASSWORD_SUCCESS:
        return action.payload;
      case RESET_PASSWORD_FAILED:
        return initialState;
      default:
        return state;
    }
  };

  export default resetPasswordReducer;

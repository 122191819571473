import { Route } from "react-router-dom";

const PublicRoute = ({ component: Component, ...children }) => {
  return (
    <Route
      render={
        (props) =>
          <Component {...props} {...children} />
      }
    />
  )
};

export default PublicRoute;

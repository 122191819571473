import {React, useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import "./retriggered.scss";
import { handleLeverOpportunity } from '../../../services/Integrations';
import Loader from '../../organisms/Loader';
import TokenError from './TokenError';


const Retriggered = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const [loader, setLoader] = useState(true);
  const [showPage, setShowPage] = useState(false);
  const [name, setName] = useState('');
  const [four, setFour] = useState(true);
  const [error_msg, setErrorMsg] = useState("");
  const fetchData = async ()=>{
    const headers = {
      "X-Client-Request-Access-Token":token,
    }
    let lever = await handleLeverOpportunity(headers);

    if (lever.status === 200){
      setLoader(false);
      setName(lever?.data?.candidate_name);
      setShowPage(true);
    }
    else if (lever?.response?.status === 409){
      setLoader(false);
      setShowPage(true);
      setFour(false);
    }
    else{
      setLoader(false);
      setErrorMsg(lever.response.data.msg);
      setShowPage(false);
    }
  }

  useEffect(()=>{
    fetchData();
  },[])

  return (
    <>
      {
        loader?<Loader />:
        showPage?(
          <section className="retriggered-static">
              <header className="header-reset">
                <span>INTERVIEW SCHEDULING</span>
              </header>
                <div className='reset-box'>
                  <div className="reset-first-box">
                    {
                      !four?<img src="./images/retriggered.svg" alt="Retriggered" />:<img src="./images/reset.svg" alt="Success" />
                    }
                  </div>
                  <div className="reset-second-box">
                    {
                      four?(
                        <>
                          <h3>Application <br/>Re-triggered!</h3>
                          <p>We will retry scheduling the interview for<br/> <span>{name} </span>
                          with your updated changes, Thank you.
                          </p>
                        </>
                      ):(
                        <>
                          <h3>Application <br/>Already Re-triggered!</h3>
                          <p>Using the link again will have no effect since the operation <br/> has already been performed. </p>
                        </>
                      )
                    }
                    <button>
                      <a href="https://hire.lever.co/" style={{all:'unset'}}>BACK TO LEVER</a>
                    </button>
                  </div>
                </div>
            </section>
        ): <TokenError error_msg={error_msg} />
      }
    </>
  )
}

export default Retriggered

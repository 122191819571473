export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const resetSuccess = (user: any) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: user,
});

export const resetFailed = (user: any) => ({
  type: RESET_PASSWORD_FAILED,
});

import React, {useEffect} from 'react';
import useScript from "../hooks/useScript";
import config from '../config/env';

const GA_MEASUREMENT_ID = config.ga.measurementId;

const GoogleAnalytics = ({ children }) => {
  useScript(`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      window.dataLayer.push(arguments);
    };

    window.gtag('js', new Date());
    window.gtag('config', GA_MEASUREMENT_ID);
  }, []);

  return <>{children}</>;
};

export default GoogleAnalytics;

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

export const loginSuccess = (access_token, refresh_token) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: {
    access_token,
    refresh_token
  }
});

export const logoutSuccess = () => ({
  type: AUTH_LOGOUT_SUCCESS,
  payload: null
});

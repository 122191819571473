import { Redirect, Route } from "react-router-dom";

const AuthRoute = ({ component: Component, isAuthenticated, ...children }) => (
  <Route
    render={
      (props) =>
        isAuthenticated
          ? <Redirect to="/dashboard" />
          : <Component {...props} {...children} />
    }
  />
);

export default AuthRoute;

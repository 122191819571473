import { POSITIONS_FETCH_SUCCESS } from "../actions/Position";

export const initialState = {
  list: []
}

export const EMPTY_POSITION = {
  position_id: null,
  position_name: '',
  position_description: '',
  round_details: [],
  tags: [],
};

const positionsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case POSITIONS_FETCH_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    default:
      break;
  }
  return state;
}

export default positionsReducer;
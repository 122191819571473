import React from "react";
import zipy from 'zipyai';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react'
import { ToastContainer } from "react-toastify";
import configureStore from "./store";
import ClientProvider from "./services/ClientProvider";
import AppRouter from "./routes";
import GoogleAnalytics from "./services/GoogleAnalytics";

const { persistor, store } = configureStore();

const App = () => {
  if (process.env.NODE_ENV === 'production') {
    zipy.init('7e61bd4d');
  }

  return (
    <GoogleAnalytics>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
            <div className="App">
              <ClientProvider>
                <AppRouter />
              </ClientProvider>
            </div>
        </PersistGate>
        <ToastContainer/>
      </Provider>
    </GoogleAnalytics>
  );
};

export default App;

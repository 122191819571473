import {UPDATE_PROFILE_IMAGE} from "../actions/updateProfilePic";

const initialState = {};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_IMAGE:
      return { ...state, [0]: action.payload.image };

    default:
      return state;
  }
};

export default profileReducer;

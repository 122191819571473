import { AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_SUCCESS } from "../actions/Auth";

const initialState = {
  access_token: null,
  refresh_token: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return action.payload;
    case AUTH_LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;

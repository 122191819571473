import axios from "./axios";

export const downloadFile = (url, fileName) => {
  axios({
    url: `/download_file?${url}`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  });
};

export const downloadVideo = (url) => {
  return axios.get(`/utils/file/download?${url}`)
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err.response;
  });
};

export const downloadResumeUrl = (url, HeaderValue) => {
  return axios(
    {
      method: "POST",
      url:`/utils/file/download?${url}`,
      data: {
        headers: HeaderValue,
      },
    }
  )
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err.response;
  });
};

// Use this API to Unarchive Video
export const unArchiveVideo = (url) => {
  return axios({
    url: `/api/utils/file/retrieve?${url}`,
    method: "GET",
  });
};

export const uploadResume = (payload) => {
  const apiEndpoint = '/parse_resume'
  return axios.post(apiEndpoint, payload)
  .then(response => {
      return response;
  })
};

export const imageFile = (url) => {
  return axios({
    url: `/download_file?${url}`, //your url
    method: "GET",
    responseType: "blob", // important
  });
};

export const uploadFileV2 = async (file) => {
  try {
    const response = await axios({
      url: `/utils/file/upload/${file.name}`, //your url
      method: "get"
    });

    var formData = new FormData();

    Object.keys(response.data.fields).forEach((key) => {
      formData.append(key, response.data.fields[key]);
    });

    formData.append("file", file);

    const upload = await axios({
      url: response.data.url,
      method: "post",
      data: formData,
      headers:{ Authorization: "" }
    });

    if (upload.status === 201) {
      return response;
    }
    // return upload.data;
  } catch (err) {
  }
};

export const getFileURL = async (url) => {
  const response = await axios({
    url: `/utils/file/download?${url}`, //your url
    method: "GET",
  });
  return response;
};

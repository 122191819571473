import {UPDATE_SELECTED_CLIENT} from "../actions/SelectedClient";

const initialState = {};

const selectedClientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_CLIENT:
      return action.payload.selectedClient;

    default:
      return state;
  }
};

export default selectedClientsReducer;

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import axios from "../services/axios";
import swal from "sweetalert";
import user from "./reducers/User";
import auth from "./reducers/Auth";
import clients from "./reducers/Clients";
import selected_client from "./reducers/SelectedClient";
import client_images from "./reducers/ClientImages";
import profile_pic from "./reducers/ProfileImage";
import positions from "./reducers/Positions";
import interview from "./reducers/Interview";
import dashboard from "./reducers/DashboardStats";
import profile from "./reducers/ComapnyProfile";
import reschedule from "./reducers/Reschedule";
import history from "./reducers/History";
import edit_interview from './reducers/EditInterview'
import resetPassword from './reducers/resetPassword';
import forgotPassword from './reducers/ForgotPassword';

import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage";
import {refreshAuth} from "../services/auth";
import SwtAlert from "../Utils/SwtAlert";

export default function configureStore() {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const config = {
    key: "root",
    storage,
    debug: true
  };

  const appReducer = persistCombineReducers(config, {
    /* your app’s top-level reducers */
    user: user,
    auth: auth,
    clients: clients,
    selected_client: selected_client,
    client_images: client_images,
    positions: positions,
    interview: interview,
    dashboard: dashboard,
    reschedule: reschedule,
    profile: profile,
    history: history,
    edit_interview: edit_interview,
    profile_pic: profile_pic,
    resetPassword: resetPassword,
    forgotPassword: forgotPassword
  });

  const rootReducer = (state, action) => {

    if (action.type === "persist/REHYDRATE") {
      const payload = action.payload;
      if (payload?.auth?.access_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${payload.auth.access_token}`;
      } else {
        axios.defaults.headers.common['Authorization'] = null;
      }
    }

    if (action.type === "UPDATE_SELECTED_CLIENT") {
      const { _persist, auth, user, clients, selected_client, client_images } = state;
      state = { _persist, auth, user, clients, selected_client, client_images};
    }

    if (action.type === "AUTH_LOGOUT_SUCCESS") {
      const { _persist } = state;
      state = { _persist };
      axios.defaults.headers.common['Authorization'] = null;
    }

    return appReducer(state, action);
  };

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, logger))
  );

  const { dispatch } = store; // direct access to redux store.
  let isRefreshing = false;
  let UNAUTHORIZED_REQUESTS_QUEUE = [];
  const UNAUTHORIZED = 401;

  const handleLogout = (type) => {
    axios.defaults.headers.common['Authorization'] = null;
    if(type != 'refreshExpired') {
      swal("Unauthorized Access! Please login again", {
        icon: "warning",
        buttons: false,
        timer: 3000,
      });
    }
  
    dispatch({
      type: "SET_AUTH_USER",
      payload: {},
    });
    dispatch({
      type: "AUTH_LOGOUT_SUCCESS",
      payload: null,
    });
  };

  const handleAuthUpdate = (access_token, refresh_token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    dispatch({
      type: "AUTH_LOGIN_SUCCESS",
      payload: {
        access_token,
        refresh_token
      },
    });
  };

  const processUnauthorizedQueue = (error, token = null) => {
    UNAUTHORIZED_REQUESTS_QUEUE.forEach((p) => {
      if (error) {
        p.reject(error);
      } else {
        p.resolve(token);
      }
    });

    UNAUTHORIZED_REQUESTS_QUEUE = [];
  };

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error?.response?.status;
      if (status === UNAUTHORIZED && !error?.config?.__isRetryRequest) {
        try {
          if (isRefreshing) { 
            try {
                if (error?.config?.url == "/auth/refresh" && error?.response?.data?.msg == "Token has expired" && status === UNAUTHORIZED ){
                SwtAlert("Your login session has expired. Please login again...", 4000, "error", false);
                // set time out time is set to 4 sec so that we can logout it after swtalert disappears.
                setTimeout(() => {
                  handleLogout('refreshExpired');
                },4000)
              }


              const token = await new Promise((resolve, reject) => {
                UNAUTHORIZED_REQUESTS_QUEUE.push({ resolve, reject });
              });
              error.config.headers.Authorization = `Bearer ${token}`;
              return axios(error.config);
            }
            catch (e) {
              return e;
            }
          }

          const currentState = store.getState();
          const { refresh_token: current_refresh_token } = currentState.auth;

          if (!current_refresh_token) {
            handleLogout();
            return;
          }

          isRefreshing = true;
          error.config.__isRetryRequest = true;
          return new Promise(async (resolve, reject) => {
            try {
              const {access_token, refresh_token} = await refreshAuth(current_refresh_token);
              handleAuthUpdate(access_token, refresh_token);

              isRefreshing = false;
              error.config.headers.Authorization = `Bearer ${access_token}`;
              processUnauthorizedQueue(null, access_token);
              resolve(axios(error.config));
            } catch (e) {
              processUnauthorizedQueue(e, null);
              reject(error.response);
            }
          });
        } catch(e) {
          handleLogout();
        }
      }

      return Promise.reject(error);
    }
  );

  const persistor = persistStore(store);

  return { persistor, store };
}


const config = {
    local: {
        ga: {
            measurementId: "G-VR2SMP7T1W"
        },
        api: {
            baseUrl: "http://localhost:5000/api",
        }
    },
    dev: {
        ga: {
            measurementId: "G-8KT0N0LMPH"
        },
        api: {
            baseUrl: "https://dev.api.interviewvector.com/api",
        }
    },
    mirror: {
        ga: {
            measurementId: "G-QNJZCS6QZQ"
        },
        api: {
            baseUrl: "https://mirror.api.interviewvector.com/api",
        }
    },
    stage: {
        ga: {
            measurementId: ""
        },
        api: {
            baseUrl: "https://stage.api.interviewvector.com/api",
        }
    },
    test: {
        ga: {
            measurementId: "G-VR2SMP7T1W"
        },
        api: {
            baseUrl: "https://test.api.interviewvector.com/api",
        }
    },
    prod: {
        ga: {
            measurementId: "G-5GZ5MT68RX"
        },
        api: {
            baseUrl: "https://api.interviewvector.com/api",
        }
    }
};

const env = process.env.REACT_APP_STAGE || "local";

export default config[env];

import { SET_AUTH_USER, TIME_ZONE_ADD, USER_NAME_ADD } from "../actions/User";

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      return action.payload;
    case TIME_ZONE_ADD:
      return {
        ...state,
        user_config:{
          ...state.user_config,
          timezone: action.payload
        }
      };
    case USER_NAME_ADD:
      return {
        ...state,
        name: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;

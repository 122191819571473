import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import {formatTemplate} from "../Utils/template";

const ProtectedRoute = ({ redirect, params, authenticator, location, ...children }) => {
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const [hasAccess, setHasAccess] = useState(null);
  const [payload, setPayload] = useState(null);
  const [redirectURL, setRedirectURL] = useState(null);

  useEffect(() => {
    if (!token) {
      return;
    }

    window?.sessionStorage.setItem('temporary_access_token', token);
    authenticator(token)
        .then((data) => {
          setHasAccess(true);
          setPayload(data);
        })
        .catch(_ => {
          setHasAccess(false);
        })
  }, [authenticator, token]);

  useEffect(() => {
    if (!hasAccess || !payload) {
      return;
    }

    const templateParams = params.map(param => payload[param] || "");
    const redirectTo = formatTemplate(redirect)(...templateParams);
    setRedirectURL(redirectTo);
  }, [hasAccess, payload]);

  if (!token) {
    return <Redirect to="/login"/>;
  }

  if (hasAccess === null || redirectURL === null) {
    return null;
  }

  return hasAccess && redirectURL && <Redirect to={redirectURL} />;
};

export default ProtectedRoute;
